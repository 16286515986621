/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Link from '@components/Link';
import Logo from '@images/logo-white.svg';
import FooterImageLinkItem from '@components/Navigation/Footer/Elements/FooterImageLinkItem';
import { faArrowCircleLeft, faVolumeDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeadSEO from '@components/Navigation/HeadSEO';

const Memorial = () => {
  const data = useStaticQuery(graphql`
    query {
      legacy: contentfulAsset(title: { eq: "Legacy Footer Logo" }) {
        description
        gatsbyImageData(height: 70, layout: FIXED)
      }
    }
  `);

  const { legacy } = data;
  const videoUrl = 'https://videos.ctfassets.net/x2t7lek2vf7h/113SOrzXoY971rTcmegrMd/49310afc3104405d408faec82da16ef1/Memorial_1-14-21.mp4?autoplay=1&mute=1';
  const raceUrl = 'http://new.smm.org/exhibits/race';

  const sound = () => {
    const video = document.getElementById('video');
    if (video.muted) {
      video.muted = 0;
    } else {
      video.muted = 1;
    }
  };
  return (
    <div style={{ backgroundColor: 'black' }}>
      <nav aria-label="Main navigation" className="sticky-top" data-cy="app-nav">
        <div
          style={{
            backgroundColor: 'black',
            padding: '15px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link to="http://new.smm.org">
            <Logo style={{ height: '75px' }} />
          </Link>
        </div>
      </nav>
      <main>
        <h1 style={{ color: 'white', textAlign: 'center', paddingBottom: '20px' }}>In Memory</h1>
        <div
          style={{
            color: 'white',
            textAlign: 'center',
            paddingBottom: '20px',
            paddingLeft: '10%',
            paddingRight: '10%',
          }}
        >
          This video acknowledges the deaths of Black people who died during police encounters in
          2020.
          <br />
          The content may be triggering or traumatic for some viewers.
          <div style={{ color: 'white', paddingTop: '20px' }} onClick={() => sound()}>
            Audio recommended:
            <FontAwesomeIcon
              size="lg"
              icon={faVolumeDown}
              style={{ color: 'white', marginLeft: '15px' }}
            />
          </div>
        </div>

        <div>
          <video
            autoPlay
            controls
            muted={1}
            id="video"
            style={{ width: '100%', height: 'auto', outline: 'none' }}
          >
            <source src={videoUrl} />
          </video>
        </div>
        <div style={{ color: 'white' }}>
          <h2 style={{ color: 'white', textAlign: 'center', paddingTop: '20px' }}>
            There is no central data repository for police-related deaths.
          </h2>
          <p style={{ textAlign: 'center', paddingLeft: '10%', paddingRight: '10%' }}>
            Our list was created from data compiled by&nbsp;
            <a
              href="https://www.cbsnews.com/pictures/black-people-killed-by-police-in-the-u-s-in-2020/"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'white' }}
            >
              CBS News
            </a>
            ,&nbsp;
            <a
              href="https://www.washingtonpost.com/graphics/investigations/police-shootings-database/"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'white' }}
            >
              <em>The Washington Post</em>
            </a>
            ,&nbsp;
            <a
              href="https://fatalencounters.org/"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'white' }}
            >
              FatalEncounters.org
            </a>
            , and&nbsp;
            <a
              href="https://fatalencounters.org/"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'white' }}
            >
              MappingPoliceViolence.org
            </a>
            . No matter the source, this much is clear: Black Americans are much more likely to
            experience police violence than their white neighbors are.
          </p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <div style={{ fontSize: '25px', display: 'flex' }}>
            <a href={raceUrl} style={{ color: 'white' }}>
              <FontAwesomeIcon
                size="lg"
                icon={faArrowCircleLeft}
                style={{ paddingRight: '10px' }}
              />
              <b>Back to</b>
              &nbsp;
              <em>RACE: Are We So Different?</em>
            </a>
          </div>
        </div>
      </main>
      <footer>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          <FooterImageLinkItem
            ariaLabel="Read our clean water, land, and legacy amendment"
            link="https://www.smm.org/legacy"
            description={legacy.description}
            image={legacy}
            alt="Legacy Amendment Logo"
          />
        </div>
      </footer>
    </div>
  );
};

export default Memorial;

export function Head() {
  return (
    <HeadSEO
      pageTitle="In Memory"
      pageDescription="This video acknowledges the deaths of Black people who died during police encounters in 2020. The content may be triggering or traumatic for some viewers."
      pageAltText="Say their names"
      pageImgUrl="https://images.ctfassets.net/x2t7lek2vf7h/3vfTft0cAkn72KwBogZfuB/9b5ece2d2acf660bd0882f7ed8677254/Screen_Shot_2021-01-14_at_3.29.33_PM.png"
    />
  );
}
